module.exports = {
  defaultTitle: 'Youssef bayad',
  logo: 'https://joseph-bayad.web.app/favicon/favicon-512.png',
  author: 'Youssef bayad',
  url: 'https://joseph-bayad.web.app',
  legalName: 'Youssef bayad',
  defaultDescription: 'I’m Youssef Bayad and I’m a Frond-end developer!',
  socialLinks: {
    twitter: 'http://www.twitter.com/bayad_jo',
    github: 'https://github.com/youssefbayad',
    linkedin: 'https://www.linkedin.com/in/youssef-bayad-5584171b4/',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@bayad_jo',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
};
